html, body, div#root {
    height:100%;
}
div#root {
    display:flex;
    background-color:#2C3034;
}
.finder {
    margin:auto;
    width:50%;
    min-width:350px;
    height:150px;
}
Button h1 {
    color:#B56F79;
}