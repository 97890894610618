div#map-container {
    display:flex-column;
    width:100%;
    height:100%;
}
div#header {
    height:1px;
    width:100%;
}
div#map {
    width:100%;
    height:100%;
}
div#header button {
    position: absolute;
    left: 200px;
    top: 12px;
    z-index: 99;
}
span.open {
    color:green;
}
span.closed {
    color:red;
}